import axios from 'axios';
import _ from 'lodash';

import passwordComponent from '../../form/field/password';

const formFields = {
    getDom(field, withLabel = true, withError = true) {
        if (typeof this[field.type] === 'function') {
            const rowAttributes = Object.entries(field.row_attr || {}).map(([key, value]) => `${key}=${value}`);
            let dom = `<div ${rowAttributes.join(' ')}>`;

            // For checkbox, label must go after input
            if (typeof field.label !== 'undefined' && field.type !== 'checkbox' && withLabel) {
                dom += this.getLabel(field.label, field.id);
            }

            dom += this[field.type](field);

            if (withError) {
                dom += this.getErrorContainer(field.id);
            }

            return `${dom}</div>`;
        }

        return '';
    },
    getActionsFirstStep() {
        return '<ul class="tw-flex tw-justify-between tw-py-4 tw-border-solid tw-border-t tw-border-gray-500">'
            + '<li class="tw-mr-4">'
                + '<button type="button" class="tw-text-black tw-font-medium tw-bg-white '
                    + 'tw-py-4 tw-px-12 tw-rounded-sm '
                    + 'tw-border-solid tw-border tw-border-gray-500 hover:tw-border-gray-700 focus:tw-border-gray-700 '
                    + 'tw-transition tw-ease-in-out tw-duration-200 tw-outline-none" id="sso-return-to-login"'
                + '>'
                    + 'Retour'
                + '</button>'
            + '</li>'
            + '<li class="tw-mr-4">'
                + '<button type="button" class="tw-text-white tw-font-medium tw-bg-primary hover:tw-bg-opacity-75 '
                    + 'tw-py-4 tw-px-12 tw-rounded-sm '
                    + 'tw-border-solid tw-border tw-border-primary hover:tw-border-opacity-25 focus:tw-border-opacity-25 '
                    + 'tw-transition tw-ease-in-out tw-duration-200 tw-outline-none" id="sso-submit-first-step" '
                    + 'data-layer-event="etuEvent" data-layer-event-cat="Inscription" '
                    + 'data-layer-event-act="Etape 1 validée"'
                + '>'
                    + 'Valider'
                + '</button>'
            + '</li>'
        + '</ul>';
    },
    getActionsSecondStep() {
        return '<ul class="tw-flex tw-justify-between tw-py-4 tw-border-solid tw-border-t tw-border-gray-500">'
            + '<li class="tw-mr-4">'
                + '<button type="button" class="tw-text-black tw-font-medium tw-bg-white '
                    + 'tw-py-4 tw-px-12 tw-rounded-sm '
                    + 'tw-border-solid tw-border tw-border-gray-500 hover:tw-border-gray-700 focus:tw-border-gray-700 '
                    + 'tw-transition tw-ease-in-out tw-duration-200 tw-outline-none" id="sso-second-step-back"'
                + '>'
                    + 'Retour'
                + '</button>'
            + '</li>'
            + '<li>'
            // eslint-disable-next-line max-len
                + '<button type="button" class="tw-text-white tw-w-auto sm:tw-w-160px tw-font-medium tw-bg-primary hover:tw-bg-opacity-75 '
                    + 'tw-py-4 tw-pr-4 tw-pl-6 tw-rounded-sm '
                    + 'tw-border-solid tw-border tw-border-primary hover:tw-border-opacity-25 focus:tw-border-opacity-25 '
                    + 'tw-transition tw-ease-in-out tw-duration-200 tw-outline-none" id="sso-second-step-next" '
                    + 'data-layer-event="etuEvent" data-layer-event-cat="Inscription" '
                    + 'data-layer-event-act="Etape 2 validée"'
                + '>'
                    + '<span>Suivant</span><i class="fa fa-arrow-right fa-w-14 tw-ml-2"></i>'
                + '</button>'
            + '</li>'
        + '</ul>';
    },
    getActionQualify() {
        return '<ul class="tw-flex tw-justify-end tw-py-4 tw-border-solid tw-border-t tw-border-gray-500">'
            // eslint-disable-next-line max-len
            + '<li class="tw-mr-4"><button type="button" class="tw-text-white tw-font-medium tw-bg-primary hover:tw-bg-opacity-75 '
                + 'tw-py-4 tw-px-12 tw-rounded-sm '
                + 'tw-border-solid tw-border tw-border-primary hover:tw-border-opacity-25 focus:tw-border-opacity-25 '
                + 'tw-transition tw-ease-in-out tw-duration-200" id="sso-qualify-submit-button"'
            + '>'
                + 'Valider'
        + '</button>'
        + '</li></ul>';
    },
    getLabel(label, id) {
        return `<label class="tw-block tw-font-medium tw-mb-3" for="${label}" id="${id}-label">${label}</label>`;
    },
    email(field) {
        // Chrome remplace les caractères spéciaux dans les inputs email
        // L'input text permet d'envoyer le form sans modification
        field.type = 'text';

        return this.text(field);
    },
    date(field) {
        // Le navigateur remplace le champ type date par son propre calendar
        // du coup on force le type text
        field.type = 'text';

        return this.text(field, false, 'flatpickr');
    },
    password(field) {
        return this.text(field, true);
    },
    text(field, isPassword = false, customClass = '') {
        const attributes = Object.entries(field.attr || {}).map(([key, value]) => `${key}=${value}`);

        let dom = '<div class="sm:tw-w-2/5 tw-relative tw-items-center tw-mb-2">'
            + `<input type="${field.type}" class="${customClass} tw-w-full tw-h-12 `
            + 'tw-font-sans tw-text-base '
            + 'tw-pl-4 tw-pr-4 '
            + 'tw-border-solid tw-border tw-border-gray-500 tw-bg-white" '
            + `name="${field.id}" id="${field.id}" ${attributes.join(' ')}>`;

        if (isPassword) {
            const iconClasses = 'tw-absolute tw-top-0 tw-right-0 tw-text-2xl tw-text-gray-700 tw-ml-15px tw-mt-3 tw-mr-3';
            dom += `<span data-password-toggler data-password-toggler-target="${field.id}">`;
            dom += `<i data-password-toggler-action="hide" class="fal fa-fw fa-eye ${iconClasses}"></i>`;
            dom += `<i data-password-toggler-action="show" class="fal fa-fw fa-eye-slash ${iconClasses} tw-hidden"></i>`;
            dom += '</span>';
        }

        dom += '</div>';

        return dom;
    },
    phone(field) {
        field.type = 'tel';

        return this.text(field);
    },
    autocomplete(field) {
        const attributes = Object.entries(field.attr || {}).map(([key, value]) => `${key}=${value}`);

        let dom = '<div class="sm:tw-w-2/5 tw-relative tw-items-center tw-mb-2">'
            + '<div class="tw-relative">'
            + '<div class="select-input">'
            + `<input type="hidden" name="${field.id}" data-autocomplete-name="${field.id}">`
            + '<input type="text" class="tw-w-full tw-h-12 '
            + 'tw-font-sans tw-text-base '
            + 'tw-pl-4 tw-pr-4 '
            + 'tw-border-solid tw-border tw-border-gray-500 tw-bg-white sso-autocomplete" '
            + `id="${field.id}" data-sso-autocomplete-url="${field.url}" autocomplete="off" ${attributes.join(' ')}>`
            + `<div id="sso-autocomplete-list-${field.id}" class="si-list"></div>`
            + '</div></div>';

        dom += '</div>';

        return dom;
    },
    select(field) {
        if (field.options.length > 25) {
            if (field.multiple === true) {
                return this.selectMultipleCheckbox(field);
            }

            return this.selectSimple(field);
        }

        if (field.multiple === true) {
            return this.selectMultipleCheckbox(field);
        }

        return this.selectSimpleRadio(field);
    },
    selectSimple(field) {
        let options = '';
        field.options.forEach((item) => {
            options += `<option value="${item.value}">${item.label}</option>`;
        });

        // eslint-disable-next-line max-len
        return `<select id="select-simple-${field.id}" name="${field.id}" class="sm:tw-w-2/5 tw-w-full tw-h-12 tw-font-sans tw-text-base tw-pl-4 tw-pr-4 tw-mb-2 tw-border-solid tw-border tw-border-gray-500 tw-bg-white">
            ${options}
        </select>`;
    },
    selectSimpleRadio(field) {
        let dom = `<div id="container-${field.id}" class="sm:tw-flex sm:tw-flex-wrap radio-button">`;

        field.options.forEach((option) => {
            dom += `<div id="radio-${field.id}_${option.value}" class="tw-flex tw-justify-center tw-items-center">`;
            // eslint-disable-next-line max-len
            dom += '<div class="radio-button-classic tw-w-full tw-flex tw-justify-center tw-items-center sm:tw-mr-50px tw-mb-2 tw-uppercase hover:tw-text-primary hover:tw-border-primary focus:tw-text-primary focus:tw-border-primary tw-font-medium tw-font-heading tw-transition tw-ease-in-out tw-duration-200">';
            dom += '<div class="tw-w-full tw-h-full tw-text-center sm:tw-text-left tw-flex tw-items-center">';
            // eslint-disable-next-line max-len
            dom += `<input class="tw-hidden" value="${option.value}" type="radio" name="${field.id}" id="${field.id}_${option.value}" ${option.selected ? 'checked' : ''}>`;
            // eslint-disable-next-line max-len
            dom += `<label for="${field.id}_${option.value}" class="tw-w-full tw-py-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-border-solid tw-border tw-border-gray-500 tw-rounded-large tw-px-4">`;
            dom += '<span class="tw-w-5 tw-h-5 tw-inline-block tw-mr-2 tw-rounded-full tw-border tw-border-gray-500 tw-bg-white"></span>';
            dom += `${option.label}</label>`;
            dom += '</div>';
            dom += '</div>';
            dom += '</div>';
        });
        dom += '</div>';

        return dom;
    },
    selectMultipleCheckbox(field) {
        // eslint-disable-next-line max-len
        let dom = `<div class="checkbox" id="container-${field.id}"><div class="sm:tw-flex sm:tw-flex-wrap"><div class="sm:tw-flex sm:tw-flex-wrap tw-mb-8">`;
        field.options.forEach((option) => {
            // eslint-disable-next-line max-len
            dom += '<div class="checkbox-add-remove tw-relative sm:tw-flex sm:tw-flex-wrap"><div class="tw-flex tw-justify-center tw-items-center sm:tw-mr-2 tw-mb-2 tw-h-50px "><div class="tw-w-full tw-h-full tw-text-center sm:tw-text-left">';
            dom += `<input value="${option.value}" type="checkbox" name="${field.id}[]" id="${field.id}_${option.value}">`;
            // eslint-disable-next-line max-len
            dom += `<label for="${field.id}_${option.value}" class="tw-flex tw-py-2 tw-items-center tw-justify-left tw-relative tw-block tw-px-4 tw-w-full tw-border-solid tw-border tw-border-gray-500 tw-rounded-sm tw-uppercase hover:tw-text-primary hover:tw-border-primary focus:tw-text-primary focus:tw-border-primary tw-font-medium tw-font-heading tw-transition tw-ease-in-out tw-duration-200tw-text-sans tw-text-sm tw-leading-normalBis">`;
            dom += '<i class="fal fa-plus tw-mr-2 tw-text-gray-600"></i><i class="fal fa-minus tw-hidden tw-mr-2 tw-text-primary"></i>';
            dom += `${option.label}</label>`;
            dom += '</div></div></div>';
        });
        dom += '</div></div></div>';

        return dom;
    },
    checkbox(field) {
        // eslint-disable-next-line max-len
        return `<input type="checkbox" name="${field.id}" value=1 id="${field.id}" class="tw-hidden"/><label for="${field.id}" class="tw-block tw-pl-30px">${field.label}</label>`;
    },
    getErrorContainer(id) {
        return `<div id="${id}-error" class="sso-error-container tw-block tw-mb-2 tw-text-danger"></div>`;
    },
    handlePassword() {
        passwordComponent.init();
    },
    abortController: new AbortController(),
    handleAutocomplete() {
        const setAutocompleteValue = ({ value, label, autocomplete }) => {
            document.querySelector(`[data-autocomplete-name=${autocomplete.id}]`).value = value;
            document.querySelector(`#${autocomplete.id}`).value = label;
            const autocompleteList = document.querySelector(`#sso-autocomplete-list-${autocomplete.id}`);
            if (autocompleteList) {
                autocompleteList.classList.add('tw-hidden');
            }
        };

        const autocompletes = document.querySelectorAll('.sso-autocomplete');

        autocompletes.forEach((autocomplete) => {
            autocomplete.addEventListener('keyup', _.debounce((e) => {
                if (e.key === 'Backspace') {
                    return;
                }

                this.abortController.abort();
                this.abortController = new AbortController();

                document.querySelector(`[data-autocomplete-name=${autocomplete.id}]`).value = null;

                const search = e.target.value;
                const url = `${MY_LETUDIANT_URL}/api/proxy${autocomplete.getAttribute('data-sso-autocomplete-url')}`;

                axios.get(url, {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    params: { q: search },
                    signal: this.abortController.signal,
                })
                    .then((response) => {
                        const autocompleteList = document.querySelector(`#sso-autocomplete-list-${autocomplete.id}`);
                        let dom = '<ul>';

                        response.data.content.forEach((item) => {
                            dom += '<li '
                                + `id="${autocomplete.id}-${item.selectOptionValue}" `
                                + 'class="si-item" '
                                + `data-sso-autocomplete-label="${item.selectOptionLabel}" `
                                + `data-sso-autocomplete-value="${item.selectOptionValue}"`
                                + `>${item.selectOptionLabel}</li>`;
                        });

                        dom += '</ul>';
                        autocompleteList.innerHTML = dom;
                        autocompleteList.classList.remove('tw-hidden');

                        if (response.data.content.length === 0) {
                            const defaultValueEnabled = !autocomplete.hasAttribute('data-autocomplete-no-default-value');
                            setAutocompleteValue({
                                value: defaultValueEnabled ? autocomplete.getAttribute('data-autocomplete-empty-value') : '',
                                label: defaultValueEnabled ? autocomplete.getAttribute('data-autocomplete-empty-label') : '',
                                autocomplete,
                            });
                        } else if (response.data.content.length === 1) {
                            const item = response.data.content[0];
                            setAutocompleteValue({
                                value: item.selectOptionValue,
                                label: item.selectOptionLabel,
                                autocomplete,
                            });
                        } else {
                            response.data.content.forEach((item) => {
                                const li = document.querySelector(`#${autocomplete.id}-${item.selectOptionValue}`);
                                if (li) {
                                    li.addEventListener('click', (event) => {
                                        setAutocompleteValue({
                                            value: event.target.getAttribute('data-sso-autocomplete-value'),
                                            label: event.target.getAttribute('data-sso-autocomplete-label'),
                                            autocomplete,
                                        });
                                    });
                                }
                            });
                        }
                    });
            }, 400));
        });
    },
};

export default formFields;
