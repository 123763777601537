import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static outlets = ['form--zip-code'];

    async connect() {
        const i18n = await import('intl-tel-input/build/js/i18n/fr').then(({ countryTranslations, interfaceTranslations }) => {
            return { ...countryTranslations, ...interfaceTranslations };
        });

        const intlTelInputWithUtils = await import('intl-tel-input/build/js/intlTelInputWithUtils').then(({ default: intlTelInput }) => {
            return intlTelInput;
        });

        this.element.querySelectorAll('input[type="tel"]').forEach((phoneInput) => {
            if (intlTelInputWithUtils.getInstance(phoneInput)) {
                return;
            }

            intlTelInputWithUtils(phoneInput, {
                i18n,
                initialCountry: 'fr',
                strictMode: true,
                validationNumberType: 'FIXED_LINE_OR_MOBILE',
                placeholderNumberType: 'MOBILE',
                useFullscreenPopup: false,
            });

            phoneInput.form.addEventListener('formdata', (e) => {
                e.formData.set(phoneInput.name, intlTelInputWithUtils.getInstance(phoneInput).getNumber());
            });

            phoneInput.addEventListener('countrychange', () => {
                if (this.hasFormZipCodeOutlet) {
                    this.formZipCodeOutlet.countryValue = intlTelInputWithUtils.getInstance(phoneInput).getSelectedCountryData().iso2;
                }
            });

            if (this.hasFormZipCodeOutlet) {
                this.formZipCodeOutlet.countryValue = intlTelInputWithUtils.getInstance(phoneInput).getSelectedCountryData().iso2;
            }
        });
    }
}
