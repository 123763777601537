import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        endpoint: String,
        delay: { type: Number, default: 0 },
    };

    initialize() {
        if (new URLSearchParams(window.location.search).get('showOptinCallModal') === '1') {
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (user?.content?.connected) {
                this.showModal();
            } else {
                document.addEventListener(
                    'userSessionUpdated',
                    this.showModal.bind(this),
                    { once: true },
                );
            }
            return;
        }

        window.addEventListener('sso--favorite-created', (event) => {
            if (event.detail?.showOptinCallModal === true) {
                this.showModal();
            }
        }, { once: true });

        if (this.delayValue !== 0) {
            setTimeout(this.showModal.bind(this), this.delayValue);
        }
    }

    showModal() {
        this.element.classList.remove('tw-hidden');
    }

    accept() {
        fetch(this.endpointValue, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'include',
        })
            .then((response) => {
                if (response.ok) {
                    window.location.reload();
                }
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error('Error:', error));
    }
}
